.ee-card {
  max-width: 100%;
}

.item-image-wrapper {
  height: 11rem;
  overflow: hidden;
}

.delivery-icon {
  font-size: 1.5rem;
  color: var(--green);
}

.favorite-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
}
