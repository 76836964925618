.responsive-video {
  height: 0;
  padding-top: 25px;
  padding-bottom: 67.5%;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;

  & .widescreen {
    padding-bottom: 56.34%;
  }

  & .vimeo {
    padding-top: 0;
  }

  & embed,
  & iframe,
  & object,
  & video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
